import { Button as ButtonMantine, ButtonProps, ButtonStylesNames } from '@mantine/core';
import React, { ButtonHTMLAttributes, FC, useMemo, useState } from 'react';
import cx from 'classnames';
import Color from 'color';
import StyledTooltip from '../components/utils/styledTooltip';
import { colorsScheme, primaryColors } from '../constants/colors';
import useColorScheme from '../hooks/useColorScheme';

type ExtraButtonProps = {
    onClick?: () => void;
    disabledTooltipLabel?: string;
    variant?: 'primary' | 'secondary' | 'tertiary';
    containerClassName?: string;
    id?: string;
    inactive?: boolean;
    classNames?: Partial<Record<ButtonStylesNames, string>>;
};

export const Button: FC<
    ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps & ExtraButtonProps
> = ({
    variant = 'primary',
    disabledTooltipLabel,
    children,
    className,
    disabled,
    classNames,
    containerClassName,
    id,
    inactive,
    ...rest
}) => {
    const [isMouseInside, setIsMouseInside] = useState(false);

    const { colorScheme } = useColorScheme();
    const inactiveColor = useMemo(() => {
        if (!inactive) return undefined;
        let color: string;
        if (variant === 'primary') color = Color(primaryColors.blue).alpha(0.2).hexa();
        if (variant === 'secondary') color = Color(primaryColors.blue).alpha(0.2).hexa();
        if (variant === 'tertiary')
            color = Color(colorsScheme[colorScheme].polarisGreen).alpha(0.2).hexa();
        return color;
    }, [colorScheme, inactive, variant]);

    return (
        <StyledTooltip
            label={disabledTooltipLabel}
            position="bottom"
            disabled={!disabled || rest?.loading || !disabledTooltipLabel}
        >
            <div className={cx('w-fit', containerClassName)}>
                <ButtonMantine
                    {...rest}
                    id={id}
                    disabled={disabled || inactive}
                    onMouseEnter={() => setIsMouseInside(true)}
                    onMouseLeave={() => setIsMouseInside(false)}
                    styles={{
                        section: {
                            color:
                                variant === 'secondary'
                                    ? (isMouseInside && 'white !important') ||
                                      `${primaryColors.blue} !important`
                                    : 'inherit',
                        },
                        inner: { color: 'inherit' },
                        root: {
                            background:
                                variant === 'secondary'
                                    ? isMouseInside && inactiveColor
                                    : inactiveColor,
                        },
                    }}
                    classNames={{
                        ...classNames,
                        section: cx(
                            'group-hover:!text-navy-solid-50 group-disabled:!text-navy-solid-30',
                            classNames?.section ?? '',
                        ),
                    }}
                    className={
                        cx(
                            className,
                            '!rounded-full transition-colors ease-in-out duration-100 disabled:!cursor-not-allowed group',
                            {
                                '!bg-risd-blue hover:!opacity-80':
                                    !inactive && variant === 'primary',
                                'hover:!text-white active:!bg-opacity-60 active:!bg-risd-blue !text-navy-solid-50 hover:!bg-risd-blue':
                                    !inactive && variant === 'secondary',
                                '!bg-polaris-green hover:!opacity-80':
                                    !inactive && variant === 'tertiary',
                                '!text-xs font-normal !text-white': variant === 'primary',
                                '!text-sm font-normal !bg-transparent': variant === 'secondary',
                                '!text-xs font-normal !text-black': variant === 'tertiary',
                                'disabled:!bg-navy-solid-30 disabled:!text-navy-solid-50':
                                    !inactive,
                            },
                        ) as string
                    }
                    loaderProps={{ type: 'dots' }}
                >
                    {children}
                </ButtonMantine>
            </div>
        </StyledTooltip>
    );
};
